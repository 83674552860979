import React from "react";

import Layout from "../other/layout";
import SEO from "../other/seo";

import { useIntl, Link, FormattedMessage } from "gatsby-plugin-react-intl";

import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
const Blog = () => {
  const intl = useIntl();
  const data = useStaticQuery(graphql`
    query {
      newsImg: file(relativePath: { eq: "aboutUsHeader.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      blog1: file(relativePath: { eq: "blog1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 450, maxHeight: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      blog2: file(relativePath: { eq: "blog2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 450, maxHeight: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      blog3: file(relativePath: { eq: "blog3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 450, maxHeight: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      blog4: file(relativePath: { eq: "blog4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 450, maxHeight: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO title="Maraton Blog" />

      <div className="blogPostBody">
        <Img
          fluid={data.newsImg.childImageSharp.fluid}
          className="img-responsive"
        />
        <div className="blogPostInner">
         <h2>{intl.formatMessage({ id: "blog" })}</h2>

          <div className="container-fluid">
            <div className="blogListing col-md-6 col-sm-12">
              
              <div className="card">
                <div>
                <Link to="/EXPECTATIONS-AND-FACTS">
                  <Img
                    fluid={data.blog1.childImageSharp.fluid}
                    className="img-responsive"
                  />
                  <span className="linkText">
                    <div className="blogyst">{intl.formatMessage({ id: "blog-1" })}</div>
                  </span>
                </Link>
                </div>
              </div>
            </div>

            <div className="blogListing col-md-6 col-sm-12">
              
              <div className="card">
                <Link to="/starting-a-new-marathon">
                  <Img
                    fluid={data.blog2.childImageSharp.fluid}
                    className="img-responsive"
                  />
                  <span className="linkText">
                    <div className="blogyst">{intl.formatMessage({ id: "blog-2-1" })}</div>
                  </span>
                </Link>
              </div>
            </div>

            <div className="blogListing col-md-6 col-sm-12">
              
              <div className="card">
                <Link to="/design-an-impressive-blog">
                  <Img
                    fluid={data.blog3.childImageSharp.fluid}
                    className="img-responsive"
                  />
                  <span className="linkText">
                    <div className="blogyst">{intl.formatMessage({ id: "blog-3-1" })}</div>
                  </span>
                </Link>
              </div>
            </div>
            <div className="blogListing col-md-6 col-sm-12">              
              <div className="card">
                <Link to="/grow-your-blog-community">
                  <Img
                    fluid={data.blog4.childImageSharp.fluid}
                    className="img-responsive"
                  />
                  <span className="linkText">
                    <div className="blogyst">{intl.formatMessage({ id: "blog-4-1" })}</div>
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Blog;
